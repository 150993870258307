import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/fields";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      creationDateTo?: string;
      creationDateFrom?: string;
      status?: Array<string>;
    };
  }
): void => {
  delete resultFilters.fieldFilters.statusItem;

  formDateFilter(resultFilters, "creationDate");
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK_CODE, `fieldFilters.${fields.FILTER_OIK_CODE}.code`);
};
