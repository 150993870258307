export enum LOADING_STATUS {
  START = "START",
  FINISH = "FINISH",
  ERROR = "ERROR",
}

export interface ILoadingType {
  status: LOADING_STATUS;
  offset?: number;
}
