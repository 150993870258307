import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("syncLogOikView/getOikLogStatuses");
  });
};

export default useGetTableLibs;
