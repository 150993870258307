import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";

export const convertStatusesToTitle = (status: string): string => {
  switch (status) {
    case TK_STATUS.SUCCESSFULLY_UPDATED:
      return "Обновление завершено";
    case TK_STATUS.TIMEOUT_FOR_OIK_RESPONSE:
      return "Таймаут ответа в ОИК";
    case TK_STATUS.SENT_TO_OIK:
      return "Ожидание ответа от ОИК";
    case TK_STATUS.OIK_ERROR:
      return "Ошибка ответа от ОИК";
    default:
      return "";
  }
};
