export const convertSaveCardObject = (resultData: Record<string, unknown>): { [key: string]: unknown } => {
  return {
    ...(resultData.id ? { id: resultData.id } : {}),
    ...(resultData.recipient ? { recipient: resultData.recipient } : {}),
    name: resultData.name || "",
    trigger: resultData.trigger || "",
    transport: resultData.transport || "",
    message: resultData.message || "",
    deleted: resultData.deleted || false,
  };
};
