export const tabsTypes = {
  oik: {
    title: "ОИК",
    url: "oikTab",
  },
  journal: {
    title: "Журнал",
    url: "journalTab",
  },
};
