var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('TmpBlock',{staticClass:"mb-1",attrs:{"height":"20"}}):_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ead-info-element__title mb-1"},'div',attrs,false),on),[_vm._v(_vm._s(_vm.title))])]}}])},[_c('div',[_vm._v(_vm._s(_vm.tooltip || _vm.title))])]),(_vm.isLoading)?_c('TmpBlock',{attrs:{"height":"40"}}):_c('v-select',{staticClass:"select-filter pa-0 ma-0",attrs:{"background-color":_vm.isDisabled ? 'gray' : 'white',"append-icon":"mdi-chevron-down","color":"fontBlack","menu-props":{ 'offset-y': true, 'content-class': 'select-filter__options', 'z-index': _vm.zIndex },"outlined":!_vm.isDisabled,"value":_vm.value,"items":_vm.items,"chips":"","attach":"","placeholder":"Не задано","multiple":_vm.isMultiple,"clearable":_vm.isClearable,"clear-icon":"mdi-close-circle","deletable-chips":"","disabled":_vm.isDisabled,"item-text":"title","item-value":"value","data-cy":"select-input"},on:{"change":_vm.changeValue,"click:clear":_vm.resetShowItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= _vm.showItems - 1)?_c('v-chip',{staticClass:"select-filter__chip",attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteItem(item.value)}}},[(item.icon)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"20","max-height":"20","src":item.icon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.title))])]):_c('span',{staticClass:"select-filter__chip-content"},[_vm._v(_vm._s(item.title))])],1):_vm._e(),(index === _vm.showItems)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"select-filter__chip",on:{"click":function($event){$event.stopPropagation();return _vm.showAllChips($event)}}},'v-chip',attrs,false),on),[_c('span',[_vm._v("Еще +"+_vm._s(_vm.value.length - _vm.showItems))])])]}}],null,true)},[_c('div',_vm._l((_vm.tooltipText),function(text){return _c('div',{key:text},[_vm._v(_vm._s(text))])}),0)]):_vm._e()]}},{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"select-filter__item ml-4 mr-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-3"},[(item.icon)?_c('v-img',{attrs:{"contain":"","max-width":"20","max-height":"20","src":item.icon}}):_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',{staticClass:"select-filter__item-text"},[_vm._v(_vm._s(item.title))])])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }