




























import { defineComponent } from "@vue/composition-api";
import { tabsTypes } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/tabsTypes";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "Tabs",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      tabsTypes,
      educationIds,
    };
  },
  computed: {
    tabsTypesQty(): number {
      return Object.keys(this.tabsTypes).length;
    },
    tabEducationId(): string {
      switch (this.$route?.params?.tab) {
        case this.tabsTypes.oik.url:
          return this.educationIds.syncLogJournalTab;
        case this.tabsTypes.journal.url:
          return this.educationIds.syncLogOikTab;
        default:
          return "";
      }
    },
  },
  mounted() {
    if (!this.$route?.params?.tab) {
      this.$emit("change", this.tabsTypes.oik);
    }
  },
});
