import { ISystemNotification } from "@monorepo/monitoring/src/views/SystemNotificationsList/types/SystemNotification";
import { fields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";
import { convertStatus } from "@monorepo/monitoring/src/views/SystemNotificationsList/utils/convertApiItemToUi";

const truncate = (input: string, length: number): string => (input.length > length ? `${input.substring(0, length)}...` : input);

export const convertDataItemToTableView = (item: ISystemNotification, isFullDescription = false): ISystemNotification => {
  return {
    ...item,
    notificationDescription: isFullDescription ? item.message : truncate(item.message || "", 30),
    status: convertStatus(!!item[fields.SYSTEM_NOTIFICATION_IS_DELETED as keyof ISystemNotification]),
  };
};
