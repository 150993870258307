






























import { viewTitle } from "@monorepo/utils/src/variables/projectsData/syncLogOikView/viewTitle";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import Tabs from "./components/Tabs.vue";
import { defineComponent } from "@vue/composition-api";
import { module as SyncLogOikView } from "@monorepo/monitoring/src/store/modules/syncLogOikView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { breadcrumbs } from "./constants/breadcrumbs";
import { mapGetters, Module } from "vuex";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useGetTableLibs from "./composables/useGetTableLibs";

export interface IData {
  breadcrumbs: typeof breadcrumbs;
  viewTitle: string;
  tab: string | null;
}

export default defineComponent({
  name: "SyncLogOikView",
  components: {
    Breadcrumbs,
    Tabs,
    oikTab: () =>
      import(
        /* webpackChunkName: "OikViewTab" */
        "./components/oik/OikView.vue"
      ),
    journalTab: () =>
      import(
        /* webpackChunkName: "JournalViewTab" */
        "./components/journal/JournalView.vue"
      ),
  },
  data(): IData {
    return {
      tab: null,
      breadcrumbs,
      viewTitle,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation"]),
    ...mapGetters("syncLogOikView", ["openedId", "isTableLoading"]),
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
  },
  setup(props, { root }) {
    useGetTableLibs(root);
    useInitTableStoreModule(root, "syncLogOikView", SyncLogOikView as Module<unknown, unknown>);
    useSetStartCells(root, "syncLogOikView", root.$store.getters[`syncLogOikView/section`], [], true);
  },
});
