











































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/monitoring/src/views/SystemNotificationsList/components/Filters.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import { getQuery } from "@monorepo/monitoring/src/views/SystemNotificationsList/constants/getQuery";
import SystemNotificationsInfoModal from "@monorepo/monitoring/src/views/SystemNotificationsList/components/SystemNotificationsInfoModal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { defineComponent, ref } from "@vue/composition-api";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { module as systemNotificationsList } from "@monorepo/monitoring/src/store/modules/systemNotificationsList";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/viewTitle";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/monitoring/src/views/SystemNotificationsList/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import useGetTableLibs from "@monorepo/monitoring/src/views/SystemNotificationsList/composables/useGetTableLibs";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import DeleteFooterButton from "@monorepo/uikit/src/components/tableViews/DeleteFooterButton.vue";
import { ISystemNotification } from "@monorepo/monitoring/src/views/SystemNotificationsList/types/SystemNotification";
import { convertDataItemToTableView } from "@monorepo/monitoring/src/views/SystemNotificationsList/utils/convertDataItemToTableView";
import { fields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";
import useFooterActions from "@monorepo/utils/src/composables/useFooterActions";

const SECTION_NAME = "systemNotificationsList";
const pluralFormTitles = ["Уведомление", "Уведомления", "Уведомлений"];

export default defineComponent({
  name: "SystemNotificationsList",
  components: {
    TableSettings,
    ToggleTableCellsContainer,
    ExportButton,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    SystemNotificationsInfoModal,
    AddElement,
    Footer,
    DeleteFooterButton,
  },
  data() {
    return {
      breadcrumbs,
      viewUniqKey,
      viewTitle,
      ModalType,
      educationIds,
      pluralFormTitles,
      tableHeaders,
      fields,
      getQuery,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "isOpenFilters",
      "cells",
      "isSelectAll",
      "data",
      "openedId",
      "infiniteId",
      "selectedIds",
      "totalLength",
      "isTableLoading",
      "transportsLibrary",
      "triggersLibrary",
    ]),
    resultData(): ISystemNotification[] {
      return this.data.map((item: ISystemNotification) => {
        convertDataItemToTableView(item, false);
        return this.convertDataItemTransportToTableView(item);
      });
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_EXPORT);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_DELETE);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && (this.isShowExport || this.isShowDeleteBtn);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "getExportData",
      "addOpenedId",
      "setIsOpenFilters",
      "setSelectedIds",
      "refreshScroll",
      "deleteElement",
    ]),
    convertDataItemTransportToTableView(item: ISystemNotification) {
      return {
        ...item,
        transport: item.transport
          ? this.transportsLibrary.find((transport: { id: number; name: string; description: string }) => transport.name === item.transport)
              ?.description
          : null,
        trigger: item.trigger
          ? this.triggersLibrary.find((trigger: { id: number; name: string; description: string }) => trigger.name === item.trigger)?.description
          : null,
      };
    },
    toggleModal(value: boolean, type?: ModalType) {
      this.showModal = value;
      this.type = type || ModalType.NEW;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, event.id);
          if (event[this.fields.SYSTEM_NOTIFICATION_IS_DELETED]) {
            return this.toggleModal(true, ModalType.READ);
          }
          this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ); // NOT SIMPLIFIED!
          break;
        default:
          break;
      }
    },
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    // openModal(
    //   this: {
    //     isCanEdit: boolean;
    //     toggleModal: (value: boolean, type?: ModalType) => void;
    //   },
    //   isDeleted: boolean
    // ) {
    //   if (isDeleted) {
    //     return this.toggleModal(true, ModalType.READ);
    //   }
    //   this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ); // NOT SIMPLIFIED!
    // },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const type = ref(ModalType.EDIT);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    const { openDeleteModal } = useFooterActions(root, SECTION_NAME, pluralFormTitles, authorities.NOTIFICATION_DELETE);

    useInitTableStoreModule(root, SECTION_NAME, systemNotificationsList as Module<unknown, unknown>);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useGetTableLibs(root);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartFilters(root, SECTION_NAME, []).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
        const isCanEdit = !!root.$store.getters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.OIK_MODIFY;
        });
        type.value = isCanEdit ? ModalType.EDIT : ModalType.READ;
      }
    });
    return {
      filtersLoaded,
      showModal,
      type,
      sectionName,
      openDeleteModal,
    };
  },
});
