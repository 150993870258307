import { IActionType } from "../types/action";
import { ILoadingType, LOADING_STATUS } from "../types/loadingTypes";

export const formLoadingPayload = (name: string, status: LOADING_STATUS, offset?: number): IActionType<ILoadingType> => {
  return {
    name,
    payload: {
      status,
      offset,
    },
  };
};
