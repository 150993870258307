import { Module } from "vuex";
import { IRootState } from "@monorepo/monitoring/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/monitoring/src/views/SystemNotificationsList/utils/convertFiltersToApi";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ISystemNotification } from "@monorepo/monitoring/src/views/SystemNotificationsList/types/SystemNotification";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ITaskElement } from "@monorepo/utils/src/types/taskElement";
import axios from "axios";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";

interface ITableState {
  filters: ITableFiltersObject;
  libraries: {
    triggers: { id: string; type: string }[];
    transports: { id: string; name: string }[];
  };
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  totalLength: number;
  section?: Sections;
  isOpenFilters: boolean;
  data: ISystemNotification[];
  infiniteId: string;
  cells: string[];
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    libraries: {
      triggers: [],
      transports: [],
    },
    isOpenFilters: false,
    section: Sections.SYSTEM_NOTIFICATIONS,
    searchTemplates: [],
    sectionCells: {} as ISection,
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    totalLength: 0,
    data: [],
    cells: [],
    isTableLoading: false,
    isLoadingToggleFilters: false,
  }),
  mutations: {
    ...baseMutations,
    addTriggersLib(state: ITableState, payload: { id: string; type: string }[]) {
      state.libraries.triggers = payload;
    },
    addTransportsLib(state: ITableState, payload: { id: string; name: string }[]) {
      state.libraries.transports = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      if (!Object.prototype.hasOwnProperty.call(payload, "date")) {
        state.filters.sort = { date: state.filters.sort.date, ...payload };
        return;
      }

      state.filters.sort = { ...state.filters.sort, date: !payload.date ? SORT_TYPE.DESC : payload.date };
    },
    addData(
      state: ITableState,
      payload: {
        data: ISystemNotification[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getTriggers({ commit, state }) {
      checkExistLibrary(state.libraries.triggers, async () => {
        const { data } = await getQuery<{ id: string; type: string }[]>(QUERY_PATH.GET_NOTIFICATION_TRIGGERS);
        commit("addTriggersLib", data || []);
      });
    },
    async getTransports({ commit, state }) {
      checkExistLibrary(state.libraries.transports, async () => {
        const { data } = await getQuery<{ id: string; name: string }[]>(QUERY_PATH.GET_NOTIFICATION_TRANSPORTS);
        commit("addTransportsLib", data || []);
      });
    },
    async deleteElement(info, params: Record<string, unknown>) {
      try {
        await axios.delete(getFullPath(QUERY_PATH.GET_NOTIFICATION_LIST), { params, paramsSerializer }); // todo: change link
        return true;
      } catch (e) {
        return false;
      }
    },
    async getErrorDescription({ state }, id: string) {
      // const { data } = await getQuery<{ description: string }>(QUERY_PATH.GET_ERROR_DESCRIPTION);
      // return data;
      return state.data.find((element) => element.id === id);
    },
    async getCardElement(info, id: string) {
      const { data } = await getQuery<ITaskElement>(`${QUERY_PATH.GET_NOTIFICATION_ITEM}/${id}`);
      return data;
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        await axios[!data.id ? "post" : "put"](getFullPath(QUERY_PATH.GET_NOTIFICATION_LIST), data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<ISystemNotification[]>(QUERY_PATH.GET_NOTIFICATION_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF") {
      // todo: переделать для системы уведомлений
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(payload === "CSV" ? QUERY_PATH.GET_EVENT_CSV_LIST : QUERY_PATH.GET_EVENT_PDF_LIST, queryParams), {
        root: true,
      });
    },
  },
  getters: {
    ...baseGetters,
    triggersLibrary(state: ITableState) {
      return state.libraries.triggers;
    },
    transportsLibrary(state: ITableState) {
      return state.libraries.transports;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
