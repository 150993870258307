import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface IOikFields {
  OIK_NAME: string;
  OIK_ID: string;
  OIK_CODE: string;
  STATUS: string;
  STATUS_RUSSIAN_TEXT: string;
  TEXT_MESSAGE: string;
  CHECKBOX: string;
  NUMBER: string;
  SUCCESS_SYNC: string;
  LAST_SYNC: string;
  ID: string;
  DATE: string;
  DATE_FROM: string;
  DATE_TO: string;
  SYNC_HISTORY: string;

  FILTER_OIK_CODE: string;
}

export const fields: IOikFields = {
  ID: "id",
  OIK_ID: "id",
  STATUS_RUSSIAN_TEXT: "statusRussianText",
  OIK_NAME: "name",
  OIK_CODE: "code",
  STATUS: "status",
  NUMBER: "number",
  TEXT_MESSAGE: "textMessage",
  SUCCESS_SYNC: "successRefreshDate",
  LAST_SYNC: "lastRefreshDate",
  DATE: "creationDate",
  DATE_FROM: "creationDateFrom",
  DATE_TO: "creationDateTo",
  CHECKBOX: headerCheckboxValue,
  SYNC_HISTORY: "history",
  FILTER_OIK_CODE: "oikCode",
};
