
















































































































import { defineComponent, ref } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import SelectCard from "@monorepo/uikit/src/components/common/Select/SelectCard.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { ISystemNotificationsCardElement } from "@monorepo/monitoring/src/views/SystemNotificationsList/types/systemNotificationsCardElement";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/viewTitle";
import {
  baseModalElements,
  fullModalElements as modalElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/modalElements";
import { convertStatus } from "@monorepo/monitoring/src/views/SystemNotificationsList/utils/convertApiItemToUi";
import { convertSaveCardObject } from "@monorepo/monitoring/src/views/SystemNotificationsList/utils/convertSaveCardObject";
import { fields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";
import { ISystemNotificationFields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import moment from "moment";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { Route } from "vue-router";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { validateEmail } from "@monorepo/utils/src/api/utils";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";

export default defineComponent({
  name: "SystemNotificationsInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    SelectCard,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.NEW,
    },
  },
  data(): {
    ModalType: typeof ModalType;
    key: string;
    viewUniqKey: string;
    modalElements: IModalElementType[];
    baseModalElements: IModalElementType[];
    techElements: IModalElementType[];
    openedPanels: number[];
    fields: ISystemNotificationFields;
    zIndexSelectCard: string | number;
    isLoading: boolean;
    cardMode: typeof CardMode;
    section: Sections;
  } {
    return {
      ModalType,
      key: uuid(),
      viewUniqKey,
      modalElements,
      baseModalElements,
      techElements,
      cardMode: CardMode,
      section: Sections.EVENT,
      openedPanels: [0, 1],
      zIndexSelectCard: 201,
      fields,
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getCardElement: (value: string) => Promise<ISystemNotificationsCardElement>;
          key: string;
          formValues: Record<string, unknown>;
          transportsLibraryResult: {
            title: string;
            value: string;
          }[];
          triggersLibraryResult: {
            title: string;
            value: number;
          }[];
          getResultSaveData: (data: Record<string, unknown>) => Record<string, unknown>;
          changedFormValues: Record<string, unknown>;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getCardElement(value)
            .then((data: ISystemNotificationsCardElement) => {
              this.formValues = {
                ...data,
                status: convertStatus(data.deleted),
                created: data.created ? moment(data.created).format("YYYY-MM-DD HH:mm") : "",
                trigger: data.trigger ? this.triggersLibraryResult.find((item) => item.value.toString() === data.trigger)?.value ?? null : null,
                transport: data.transport
                  ? this.transportsLibraryResult.find((item) => item.value.toString() === data.transport)?.value ?? null
                  : null,
              };
              this.key = uuid();
              this.changedFormValues = this.getResultSaveData(this.formValues);
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.changedFormValues = this.getResultSaveData({});
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("systemNotificationsList", ["data", "openedId", "triggersLibrary", "transportsLibrary"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowRecipient(): boolean {
      if (this.formValues[fields.SYSTEM_NOTIFICATION_ACTION]) {
        const id =
          this.transportsLibrary?.find(
            (item: { id: string; name: string; description: string }) => this.formValues[fields.SYSTEM_NOTIFICATION_ACTION] === item.name
          )?.id ?? 0;
        return id === 1;
      }
      return false;
    },
    hideFieldsObject(): Record<string, boolean> {
      return {
        [this.fields.SYSTEM_NOTIFICATION_RECIPIENT]: !this.isShowRecipient,
      };
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.NOTIFICATION_DELETE);
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    triggersLibraryResult(): { title: string; value: string }[] {
      return convertToSelectItems(this.triggersLibrary, "description", "name");
    },
    transportsLibraryResult(): { title: string; value: string }[] {
      return convertToSelectItems(this.transportsLibrary, "description", "name");
    },
    title(): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return this.formValues[this.fields.SYSTEM_NOTIFICATION_NAME] as string;
        case ModalType.NEW:
        default:
          return "Новое уведомление";
      }
    },
  },
  methods: {
    ...mapActions("systemNotificationsList", ["changeOpenedId"]),
    ...mapActions("systemNotificationsList", ["getCardElement", "saveCard", "refreshScroll", "deleteElement"]),
    async deleteElementCb() {
      const isDeleted = await this.deleteElement({ ids: [this.formValues.id] });
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      }
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.deleteElementCb,
          title: `Удалить уведомление «${this.formValues.name}»?`,
        })
      );
    },
    getResultSaveData(data: Record<string, unknown>) {
      const resultData: { [key: string]: unknown } = {
        id: data.id || this.formValues.id,
        ...data,
      };
      return convertSaveCardObject(resultData);
    },
    async onSave(data: Record<string, string | boolean>) {
      let resultData = {
        id: data.id || this.formValues.id,
        [this.fields.SYSTEM_NOTIFICATION_TRIGGER]: this.formValues[this.fields.SYSTEM_NOTIFICATION_TRIGGER],
        [this.fields.SYSTEM_NOTIFICATION_ACTION]: this.formValues[this.fields.SYSTEM_NOTIFICATION_ACTION],
        ...data,
      };

      if (
        resultData?.[this.fields.SYSTEM_NOTIFICATION_RECIPIENT] &&
        !validateEmail((resultData?.[this.fields.SYSTEM_NOTIFICATION_RECIPIENT] as string) || "")
      ) {
        showNotification("Проверьте правльность заполнения Email");
        return;
      }

      if (!resultData?.[this.fields.SYSTEM_NOTIFICATION_TRIGGER]) {
        showNotification("Поле Триггер обязательно для заполнения");
        return;
      }

      if (!resultData?.[this.fields.SYSTEM_NOTIFICATION_ACTION]) {
        showNotification("Поле Транспорт обязательно для заполнения");
        return;
      }

      const isSaved = await this.saveCard(this.getResultSaveData(resultData));
      if (isSaved) {
        this.changedFormValues = this.getResultSaveData(resultData);
        this.refreshScroll();
        this.closeModal();
      }
    },
    closeModal(): void {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({} as Record<string, null | string | boolean | number | Record<string, string>[] | Record<string, string>>);
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "systemNotificationsList", Sections.EVENT, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "systemNotificationsList");

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
