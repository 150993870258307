import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  // {
  //   text: "Идентификатор",
  //   isLink: true,
  //   defaultWidth: 170,
  //   value: fields.SYSTEM_NOTIFICATION_ID,
  //   isHiddenByDefault: true,
  //   isSorted: false,
  //   isHandle: true,
  //   id: uuid(),
  //   tooltip: "Идентификатор уведомления",
  // },
  {
    text: "Наименование",
    defaultWidth: 160,
    value: fields.SYSTEM_NOTIFICATION_NAME,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    tooltip: "Наименование уведомления",
  },
  {
    text: "Триггер",
    defaultWidth: 200,
    value: fields.SYSTEM_NOTIFICATION_TRIGGER,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    tooltip: "Триггер (событие, по которому направляется системное уведомление)",
  },
  {
    text: "Транспорт",
    defaultWidth: 160,
    value: fields.SYSTEM_NOTIFICATION_ACTION,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    tooltip: "Транспорт уведомления",
  },
  {
    text: "Адресат",
    defaultWidth: 190,
    value: fields.SYSTEM_NOTIFICATION_RECIPIENT,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    tooltip: "Адресат уведомления",
  },
  {
    text: "Текст",
    value: fields.SYSTEM_NOTIFICATION_DESCRIPTION,
    defaultWidth: 200,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    tooltip: "Текст уведомления",
  },
  // {
  //   text: "Статус",
  //   value: fields.SYSTEM_NOTIFICATION_STATUS,
  //   isSorted: false,
  //   defaultWidth: 120,
  //   isHandle: true,
  //   id: uuid(),
  //   tooltip: "Флаг логического удаления",
  // },
];
