import { onBeforeMount, ComponentInstance } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("systemNotificationsList/getTransports");
    store.dispatch("systemNotificationsList/getTriggers");
  });
};

export default useGetTableLibs;
