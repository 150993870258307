import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: true,
    title: "Наименование",
    tooltip: "Наименование Системного уведомления",
    className: "info-modal__element_title",
    value: fields.SYSTEM_NOTIFICATION_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    title: "Транспорт",
    tooltip: "Транспорт уведомления",
    className: "info-modal__element_title",
    value: fields.SYSTEM_NOTIFICATION_ACTION,
    slotName: "selectTransport",
  },
  {
    id: uuid(),
    title: "Триггер",
    tooltip: "Триггер (событие, по которому направляется системное уведомление)",
    className: "info-modal__element_full-size",
    value: fields.SYSTEM_NOTIFICATION_TRIGGER,
    slotName: "selectTrigger",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Адресат",
    tooltip: "Адресат уведомления",
    placeholder: "Не задан",
    className: "info-modal__element_full-size",
    value: fields.SYSTEM_NOTIFICATION_RECIPIENT,
    isRequired: true,
    mask: cloneDeep(standardMask.email),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Текст",
    tooltip: "Текст уведомления",
    className: "info-modal__about info-modal__element_full-size info-modal__element_textarea",
    childrenClassName: "info-element-wrapped",
    value: fields.SYSTEM_NOTIFICATION_DESCRIPTION,
    fieldType: "textarea",
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
];
export const techElements = [
  // {
  //   id: uuid(),
  //   isEdited: false,
  //   title: "Идентификатор Системного уведомления",
  //   className: "info-modal__element",
  //   value: fields.SYSTEM_NOTIFICATION_ID,
  // },
  {
    id: uuid(),
    isEdited: false,
    title: "Автор изменений",
    className: "info-modal__element_title",
    value: fields.SYSTEM_NOTIFICATION_AUTHOR,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата сохранения",
    className: "info-modal__element_title",
    value: fields.SYSTEM_NOTIFICATION_DATE_SAVE,
  },
  // {
  //   id: uuid(),
  //   isEdited: false,
  //   title: "Статус",
  //   className: "info-modal__element_full-size",
  //   childrenClassName: "info-element-wrapped",
  //   value: fields.SYSTEM_NOTIFICATION_STATUS,
  // },
];
export const fullModalElements = [
  ...baseModalElements,
  ...techElements,
  {
    id: uuid(),
    title: "Редактирование / создание системных уведомлений",
    className: "info-modal__element_title",
    value: "canEdit",
  },
  {
    id: uuid(),
    title: "Удаление системных уведомлений",
    className: "info-modal__element_title",
    value: "canDelete",
  },
];
