import { fields } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/fields";

export const filtersElements = [
  {
    value: fields.SYSTEM_NOTIFICATION_NAME,
    title: "Наименование Системного уведомления",
  },
  {
    value: fields.SYSTEM_NOTIFICATION_RECIPIENT,
    title: "Адресат",
  },
  {
    value: fields.SYSTEM_NOTIFICATION_TRIGGER,
    title: "Триггер",
  },
  {
    value: fields.SYSTEM_NOTIFICATION_ACTION,
    title: "Транспорт уведомления",
  },
  {
    value: fields.SYSTEM_NOTIFICATION_STATUS,
    title: "Статус",
  },
];
