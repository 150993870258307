export interface ISystemNotificationFields {
  SYSTEM_NOTIFICATION_ID: string;
  SYSTEM_NOTIFICATION_NAME: string;
  SYSTEM_NOTIFICATION_TRIGGER: string;
  SYSTEM_NOTIFICATION_ACTION: string;
  SYSTEM_NOTIFICATION_RECIPIENT: string;
  SYSTEM_NOTIFICATION_DESCRIPTION: string;
  SYSTEM_NOTIFICATION_IS_DELETED: string;
  SYSTEM_NOTIFICATION_STATUS: string;
  SYSTEM_NOTIFICATION_AUTHOR: string;
  SYSTEM_NOTIFICATION_DATE_SAVE: string;
}

export const fields: ISystemNotificationFields = {
  SYSTEM_NOTIFICATION_ID: "id",
  SYSTEM_NOTIFICATION_NAME: "name",
  SYSTEM_NOTIFICATION_TRIGGER: "trigger",
  SYSTEM_NOTIFICATION_ACTION: "transport",
  SYSTEM_NOTIFICATION_RECIPIENT: "recipient",
  SYSTEM_NOTIFICATION_IS_DELETED: "deleted",
  SYSTEM_NOTIFICATION_DESCRIPTION: "message",
  SYSTEM_NOTIFICATION_STATUS: "status",
  SYSTEM_NOTIFICATION_AUTHOR: "author",
  SYSTEM_NOTIFICATION_DATE_SAVE: "created",
};
