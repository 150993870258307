











































































import { defineComponent } from "@vue/composition-api";
import _Base from "@monorepo/uikit/src/components/common/Select/_Base.vue";

export default defineComponent({
  name: "SelectCard",
  extends: _Base,
});
