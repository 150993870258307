import { Module } from "vuex";
import { IRootState } from "@monorepo/monitoring/src/store";
import { getQuery, getFullPath, encodeQueryData, checkExistLibrary } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { fieldFiltersValue, ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions, IVuexContext } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { convertFiltersToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { ISyncLogElement } from "@monorepo/monitoring/src/views/SyncLogOik/types/syncLogElement";
import { convertFiltersCustomToApi } from "@monorepo/monitoring/src/views/SyncLogOik/utils/convertFiltersToApi";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { changeOpenedIdPayloadValues } from "@monorepo/utils/src/utils/changeOpenedId";
import { convertStatusesToTitle } from "@monorepo/utils/src/utils/converOIKStatusesToTitle";
interface ITableState {
  filters: ITableFiltersObject & { oikSort: Record<string, SORT_TYPE>; oikFieldFilters: Record<string, fieldFiltersValue> };
  libraries: {
    oiks: Record<string, string>[];
    logStatuses: Record<string, string>[];
  };
  totalLength: number;
  oikLogTotalLength: number;
  oikLogData: ISyncLogElement[];
  data: ISyncLogElement[];
  infiniteId: string;
  cells: string[];
  journalCells: string[];
  isOpenFilters: boolean;
  autorefresh: boolean;
  section?: Sections;
  searchTemplates: ISearchTemplate[];
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObject & { oikSort: Record<string, SORT_TYPE>; oikFieldFilters: Record<string, fieldFiltersValue> } => ({
  sort: {
    lastRefreshDate: SORT_TYPE.DESC,
  },
  oikSort: {
    code: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  oikFieldFilters: {},
  initMessagesLength: 50,
});

const resultLogExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_OIK_SYNC_LOG_CSV;
    case "XLSX":
      return QUERY_PATH.GET_OIK_SYNC_LOG_XLS;
    default:
      return QUERY_PATH.GET_OIK_SYNC_LOG_PDF;
  }
};

const resultLogAllExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_ALL_SYNC_LOG_CSV;
    case "XLSX":
      return QUERY_PATH.GET_ALL_SYNC_LOG_XLS;
    default:
      return QUERY_PATH.GET_ALL_SYNC_LOG_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    cells: [],
    journalCells: [],
    totalLength: 0,
    oikLogTotalLength: 0,
    isOpenFilters: true,
    autorefresh: false,
    oikLogData: [],
    data: [],
    infiniteId: new Date().toString(),
    section: Sections.SYNC_LOG_OIK,
    searchTemplates: [],
    libraries: {
      oiks: [],
      logStatuses: [],
    },
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
  }),
  mutations: {
    ...baseMutations,
    changeOpenedId(state: ITableState, payload: changeOpenedIdPayloadValues) {
      const selectedIndex = state.oikLogData.findIndex(
        (element: { transportContainer: { id: number } }) => element.transportContainer?.id === state.filters.openedId
      );

      if (payload === changeOpenedIdPayloadValues.UP) {
        state.filters.openedId =
          selectedIndex === 0
            ? state.oikLogData[state.oikLogData.length - 1].transportContainer?.id ?? state.filters.openedId
            : state.oikLogData[selectedIndex - 1].transportContainer?.id ?? state.filters.openedId;
      }

      if (payload === changeOpenedIdPayloadValues.DOWN) {
        state.filters.openedId =
          selectedIndex === state.oikLogData.length - 1
            ? state.oikLogData[0].transportContainer?.id ?? state.filters.openedId
            : state.oikLogData[selectedIndex + 1].transportContainer?.id ?? state.filters.openedId;
      }
    },
    refreshOikLogData(state: ITableState) {
      state.oikLogData = [];
    },
    refreshData(state: ITableState) {
      state.data = [];
    },
    addOikSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.oikSort = { ...payload };
    },
    addOikFilter(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.oikFieldFilters = {
        ...state.filters.oikFieldFilters,
        ...payload,
      };
    },
    setJournalCells(state: ITableState, payload: string[]) {
      state.cells = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    resetOikLogData(
      state: ITableState,
      payload: {
        oikLogData: ISyncLogElement[];
        oikLogTotalLength: number;
      }
    ) {
      state.oikLogData = payload.oikLogData || [];
      state.oikLogTotalLength = payload.oikLogTotalLength;
    },
    addOikLogData(
      state: ITableState,
      payload: {
        oikLogData: ISyncLogElement[];
        oikLogTotalLength: number;
      }
    ) {
      state.oikLogData = state.oikLogData.concat(payload.oikLogData || []);
      state.oikLogTotalLength = payload.oikLogTotalLength;
    },
    addData(
      state: ITableState,
      payload: {
        data: ISyncLogElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data || []);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addLogOikStatusesLib(state: ITableState, payload: string[]) {
      state.libraries.logStatuses = (payload || []).map((item) => ({ value: item, title: convertStatusesToTitle(item) })) || [];
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
  },
  actions: {
    ...baseActions,
    getOikLogExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultLogExportPath(payload), queryParams), {
        root: true,
      });
    },
    getOikLogAllExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultLogAllExportPath(payload), queryParams), {
        root: true,
      });
    },
    async refreshOikLogList({ commit, state }) {
      const params = convertFiltersToApi(
        { ...state.filters, sort: { ...state.filters.oikSort }, fieldFilters: { ...state.filters.oikFieldFilters } },
        state.oikLogData.length,
        convertFiltersCustomToApi
      );
      const { data, total: totalLength } = await getQuery<ISyncLogElement[]>(QUERY_PATH.GET_OIK_SYNC_LOG, {
        ...params,
        offset: 0,
      });

      if (data !== null) {
        commit("resetOikLogData", { oikLogData: data || [], oikLogTotalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null };
    },
    async getOikLogItems({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(
        { ...state.filters, sort: { ...state.filters.oikSort }, fieldFilters: { ...state.filters.oikFieldFilters } },
        state.oikLogData.length,
        convertFiltersCustomToApi
      );
      const { data, total: totalLength } = await getQuery<ISyncLogElement[]>(QUERY_PATH.GET_OIK_SYNC_LOG, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (!params.offset) {
        commit("refreshOikLogData");
      }
      if (data !== null) {
        commit("addOikLogData", { oikLogData: data || [], oikLogTotalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null };
    },
    async getOikCardElement(info, id: string) {
      const { data } = await getQuery<ISyncLogElement>(`${QUERY_PATH.GET_OIK_SYNC_LOG}/${id}`);
      return data;
    },
    async refreshJournalList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<ISyncLogElement[]>(QUERY_PATH.GET_ALL_SYNC_LOG, {
        ...params,
        offset: 0,
      });

      if (data !== null) {
        commit("refreshData");
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null };
    },
    async getItems({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<ISyncLogElement[]>(QUERY_PATH.GET_ALL_SYNC_LOG, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (!params.offset) {
        commit("refreshData");
      }
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null };
    },
    refreshOikScroll({ commit }: IVuexContext) {
      commit("refreshOikLogData");
      setTimeout(() => {
        commit("refreshInfiniteId");
      }, 100);
    },
    addOikSort({ commit }: IVuexContext, payload: Record<string, SORT_TYPE>) {
      commit("addOikSort", payload);
    },
    addOikFilter({ commit }: IVuexContext, payload: Record<string, SORT_TYPE>) {
      commit("addOikFilter", payload);
    },
    async setSuccessGraphData(info, path: string) {
      try {
        const { data } = await getQuery<{ date: string; count: number }[]>(`${getFullPath(QUERY_PATH.GET_GRAPH_OIK_DATA)}/${path}`);
        return data;
      } catch (e) {
        return [];
      }
    },
    async setJournalCells({ commit }, payload: string[]) {
      try {
        // await axios.put(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${getters.sectionCells?.id}`, {
        //   columnStates: payload,
        // });
        commit("setJournalCells", payload);
      } catch (e) {
        console.log(e);
      }
    },
    async getOikLogStatuses({ commit, state }) {
      checkExistLibrary(state.libraries.logStatuses, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OIK_LOG_STATUS);
        commit("addLogOikStatusesLib", data);
      });
    },
  },
  getters: {
    ...baseGetters,
    journalCells(state: ITableState) {
      return state.journalCells;
    },
    usersLib(state: ITableState) {
      return state.libraries.oiks;
    },
    oikLogData(state: ITableState) {
      return state.oikLogData;
    },
    oikLogTotalLength(state: ITableState) {
      return state.oikLogTotalLength;
    },
    oikSort(state: ITableState) {
      return state.filters.oikSort;
    },
    oikFieldFilters(state: ITableState) {
      return state.filters.oikFieldFilters;
    },
    logStatusesLib(state: ITableState) {
      return state.libraries.logStatuses;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
